<template>
  <div>
    <v-toolbar v-if="showNavbar" app dark height="90">
      <v-toolbar-side-icon @click.stop="clickDrawer"></v-toolbar-side-icon>
      <img src="../assets/images/ottu-logo.png" alt="Ottu" class="ottu-logo" />
      <v-spacer></v-spacer>

      <!--top-right menu-->
      <span>{{ "Hello" }} {{ user_details.username }}</span>
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile
            v-if="user_details.is_superuser"
            :href="merchant_details.admin_url"
          >
            <v-list-tile-action>
              <v-icon>settings</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{
              $t("Administration panel")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="switchLanguage">
            <v-list-tile-action>
              <v-icon>language</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t("Switch language") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile :to="{ path: localePath('/user') }">
            <v-list-tile-action>
              <v-icon>account_box</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t("Account settings") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="logout">
            <v-list-tile-action>
              <v-icon>logout</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>{{ $t("Logout") }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
      app
      v-model="drawer"
      :right="$vuetify.rtl"
      class="side-menu"
    >
      <v-img
        v-if="merchant_details.logo_url"
        :src="merchant_details.logo_url"
        max-height="150px"
        contain
      >
      </v-img>
      <p
        class="mt-2 headline"
        v-if="merchant_details.title_en && merchant_details.title_ar"
      >
        {{ merchant_details.title_en }}
      </p>
      <p
        class="mt-2 headline"
        v-if="merchant_details.title_en && merchant_details.title_ar"
      >
        {{
          this.$i18n.locale === "ar"
            ? merchant_details.title_ar
            : merchant_details.title_en
        }}
      </p>
      <p class="subheading" v-if="merchant_details.subheader">
        {{ merchant_details.subheader }}
      </p>
      <v-list>
        <!-- Dashboard -->
        <v-list-tile
          :to="{ path: localePath('/dashboard') }"
          :exact="$route.path !== 'dashboard'"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>dashboard</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t("Dashboard") }}
          </v-list-tile-title>
        </v-list-tile>
        <!-- Express search -->
        <v-list-tile
          v-if="
            isSectionAvailable('express_search') &&
              sections.express_search.index
          "
          :to="{ path: localePath('/search/express') }"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>payment</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t("Express Search") }}
          </v-list-tile-title>
        </v-list-tile>

        <!-- Payment request-->
        <v-list-group
          v-if="isSectionAvailable('payment_request')"
          prepend-icon="payment"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Payment Request") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.payment_request.create"
            :to="{ path: localePath('/payment-request/create') }"
          >
            <v-list-tile-title>{{ $t("Create") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.payment_request.transactions"
            :to="{ path: localePath('/payment-request/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.payment_request.deleted_transactions"
            :to="{ path: localePath('/payment-request/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>

          <v-list-group
            sub-group
            v-if="
              headerOptions.payment_request &&
                hasHeadersPerm('paymentrequestpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/payment-request/table-headers') }"
            >
              <v-list-tile-title>{{ $t("Table Headers") }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Real Estate-->
        <v-list-group
          v-if="isSectionAvailable('real_estate')"
          :class="{ 'right-to-left': $vuetify.rtl }"
          prepend-icon="payment"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Real Estate") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-group sub-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Main listings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/unit_types') }"
            >
              <v-list-tile-title>{{ $t("Unit Types") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/properties') }"
            >
              <v-list-tile-title>{{
                $t("Property management")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/tenant_management') }"
            >
              <v-list-tile-title>{{
                $t("Tenant Management")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.all"
              :to="{ path: localePath('/real_estate/rent_management') }"
            >
              <v-list-tile-title>{{ $t("Rent Management") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.transactions"
              :to="{ path: localePath('/real-estate/transactions') }"
            >
              <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="sections.real_estate.deleted_transactions"
              :to="{ path: localePath('/real-estate/deleted-transactions') }"
            >
              <v-list-tile-title>{{
                $t("Deleted Paid Transactions")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Actions") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/real_estate/contract') }">
              <v-list-tile-title>{{ $t("New Contract") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/renew_contract') }"
            >
              <v-list-tile-title>{{ $t("Renew Contract") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/terminate_contract') }"
            >
              <v-list-tile-title>{{
                $t("Terminate Contract")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/manual_payment') }"
            >
              <v-list-tile-title>{{ $t("Manual Payment") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/manual_generating_payments')
              }"
            >
              <v-list-tile-title>{{
                $t("Generate Invoices")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :to="{ path: localePath('/real_estate/edit_price') }">
              <v-list-tile-title>{{ $t("Edit Price") }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <!-- <v-list-tile :to="{ path: localePath('/real_estate/advance_payment') }">
            <v-list-tile-title>{{$t("Advance Payment")}}</v-list-tile-title>
          </v-list-tile> -->
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/real-estate/table-headers') }"
            >
              <v-list-tile-title>{{ $t("Table Headers") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :to="{ path: localePath('/real_estate') }">
              <v-list-tile-title>{{ $t("Configuration") }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{
                  $t("Action listings")
                }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/real_estate/edit_price_list') }"
            >
              <v-list-tile-title>{{ $t("Edit Price List") }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/new_contract_list') }"
            >
              <v-list-tile-title>{{
                $t("New Contract List")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/renew_contract_list') }"
            >
              <v-list-tile-title>{{
                $t("Renew Contract List")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{ path: localePath('/real_estate/terminate_contract_list') }"
            >
              <v-list-tile-title>{{
                $t("Terminate Contract List")
              }}</v-list-tile-title>
            </v-list-tile>
            <!-- <v-list-tile :to="{ path: localePath('/real_estate/advance_payment_list') }">
              <v-list-tile-title>{{$t("Advance Payment List")}}</v-list-tile-title>
            </v-list-tile> -->
            <v-list-tile
              :to="{ path: localePath('/real_estate/manual_payment_list') }"
            >
              <v-list-tile-title>{{
                $t("Manual Payment List")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/suspended_resumed_contract')
              }"
            >
              <v-list-tile-title>{{
                $t("Suspended Contracts")
              }}</v-list-tile-title>
            </v-list-tile>

            <v-list-tile
              :to="{ path: localePath('/real_estate/modified_contract') }"
            >
              <v-list-tile-title>{{
                $t("Modified Contracts")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
          <v-list-group sub-group v-if="sections.real_estate.all">
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Reports") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/shortly_expired_contracts')
              }"
            >
              <v-list-tile-title>{{
                $t("Shortly Expired Contracts")
              }}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              :to="{
                path: localePath('/real_estate/generated_reports')
              }"
            >
              <v-list-tile-title>{{
                $t("Generated Reports")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <!-- Bulk -->
        <v-list-group v-if="isSectionAvailable('bulk')" prepend-icon="update">
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Bulk") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.bulk.upload"
            :to="{ path: localePath('/bulk/uploads') }"
          >
            <v-list-tile-title>{{ $t("Uploads") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.bulk.transactions"
            :to="{ path: localePath('/bulk/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.bulk.deleted_transactions"
            :to="{ path: localePath('/bulk/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.bulk && hasHeadersPerm('bulk')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/bulk/table-headers') }">
              <v-list-tile-title class="submenu">{{
                "Table Headers"
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Customer Payment -->
        <v-list-group
          v-if="isSectionAvailable('customer_payment')"
          prepend-icon="account_balance_wallet"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{
                $t("Customer payment")
              }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.customer_payment.transactions"
            :to="{ path: localePath('/customer-payment/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.customer_payment.deleted_transactions"
            :to="{ path: localePath('/customer-payment/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.customer_payment.payment_restriction"
            :to="{ path: localePath('/customer-payment/payment-restriction') }"
          >
            <v-list-tile-title>{{
              $t("Payment Restriction")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              headerOptions.customer_payment &&
                hasHeadersPerm('customerpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/customer-payment/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <!-- Catalogue -->
        <v-list-group
          v-if="isSectionAvailable('catalogue')"
          prepend-icon="store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Catalogue") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.catalogue.transactions"
            :to="{ path: localePath('/catalogue/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.catalogue.deleted_transactions"
            :to="{ path: localePath('/catalogue/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.catalogue && hasHeadersPerm('catalogue')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/catalogue/table-headers') }">
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <v-list-group
          v-if="isSectionAvailable('e_commerce')"
          prepend-icon="local_grocery_store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("E-commerce") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.e_commerce.transactions"
            :to="{ path: localePath('/e-commerce/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{ path: localePath('/e-commerce/deleted-transactions') }"
            v-if="sections.e_commerce.deleted_transactions"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.e_commerce && hasHeadersPerm('ecompayment')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/e-commerce/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <v-list-group
          v-if="isSectionAvailable('session')"
          prepend-icon="local_grocery_store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Session") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.session.transactions"
            :to="{ path: localePath('/session/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{ path: localePath('/session/deleted-transactions') }"
            v-if="sections.session.deleted_transactions"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.session && hasHeadersPerm('sessionpayment')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/session/table-headers') }">
              <v-list-tile-title>{{ $t("Table Headers") }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>
        <v-list-group
          v-if="isSectionAvailable('shopify')"
          prepend-icon="$vuetify.icons.shopify"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Shopify") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.shopify.transactions"
            :to="{ path: localePath('/shopify/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="{ path: localePath('/shopify/deleted-transactions') }"
            v-if="sections.shopify.deleted_transactions"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.shopify && hasHeadersPerm('shopifypayment')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/shopify/table-headers') }">
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <v-list-group
          v-if="isSectionAvailable('event')"
          prepend-icon="store"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Events") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.event.transactions"
            :to="{ path: localePath('/event/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.event.deleted_transactions"
            :to="{ path: localePath('/event/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.event.bookings"
            :to="{ path: localePath('/event/bookings') }"
          >
            <v-list-tile-title>{{ $t("Bookings") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.event.create"
            :to="{ path: localePath('/event/create') }"
          >
            <v-list-tile-title>{{ $t("Create") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.event.fulfillment"
            :to="{ path: localePath('/event/fullfilment') }"
          >
            <v-list-tile-title>{{ $t("Fullfilment") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="headerOptions.event && hasHeadersPerm('eventpayment')"
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile :to="{ path: localePath('/event/table-headers') }">
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <v-list-group
          v-if="isSectionAvailable('food_ordering')"
          prepend-icon="fastfood"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title>{{ $t("Food ordering") }}</v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.food_ordering.transactions"
            :to="{ path: localePath('/food_ordering/transactions') }"
          >
            <v-list-tile-title>{{ $t("Transactions") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.deleted_transactions"
            :to="{ path: localePath('/food_ordering/deleted-transactions') }"
          >
            <v-list-tile-title>{{
              $t("Deleted Paid Transactions")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.orders"
            :to="{ path: localePath('/food-ordering/orders') }"
          >
            <v-list-tile-title>
              <span>{{ $t("Orders") }}</span>
              <!-- <span
                v-if="isFCMConfigSet && $fcm_ready"
                style="margin-left: 10px"
              >
                <NotificationBadge
                  v-if="user_details.receive_order_notification"
                />
              </span> -->
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.product_bulk_create"
            :to="{ path: localePath('/food-ordering/bulk-create') }"
          >
            <v-list-tile-title>{{
              $t("Product bulk create")
            }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.branches"
            :to="{ path: localePath('/food-ordering/branches') }"
          >
            <v-list-tile-title>{{ $t("Branch") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.food_ordering.fulfillment"
            :to="{ path: localePath('/food-ordering/fulfillment') }"
          >
            <v-list-tile-title>{{ $t("Fulfillment") }}</v-list-tile-title>
          </v-list-tile>
          <v-list-group
            sub-group
            v-if="
              headerOptions.food_ordering &&
                hasHeadersPerm('foodorderingpayment')
            "
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>{{ $t("Settings") }}</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile
              :to="{ path: localePath('/food_ordering/table-headers') }"
            >
              <v-list-tile-title class="submenu">{{
                $t("Table Headers")
              }}</v-list-tile-title>
            </v-list-tile>
          </v-list-group>
        </v-list-group>

        <!-- permisions request-->
        <v-list-group prepend-icon="store" v-if="isSectionAvailable('ticket')">
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-title
                >{{ $t("Tickets") }}
                <!-- <span style="margin-inline-start: 10px">
                  <RequestNotificationBadge :getters="enabledNotification()" />
                </span> -->
              </v-list-tile-title>
            </v-list-tile>
          </template>
          <v-list-tile
            v-if="sections.ticket.user_permission_changes"
            :to="{ path: localePath('/ticket/approve-permissions') }"
          >
            <v-list-tile-title>
              {{ $t("Permissions Requests") }}
              <span style="margin-inline-start: 10px">
                <RequestNotificationBadge :getters="permissionRequestGetters" />
              </span>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            v-if="sections.ticket.operations_approval"
            :to="{ path: localePath('/ticket/approve-refund-void') }"
          >
            <v-list-tile-title>
              {{ $t("Operations Requests") }}
              <span style="margin-inline-start: 10px">
                <RequestNotificationBadge :getters="operationRequestGetters" />
              </span>
            </v-list-tile-title>
          </v-list-tile>
        </v-list-group>

        <v-list-tile
          :to="{ path: localePath('/generated-reports') }"
          :class="{ 'right-to-left': $vuetify.rtl }"
        >
          <v-list-tile-action>
            <v-icon>store</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t("Generated Reports") }}
          </v-list-tile-title>
        </v-list-tile>

        <v-list-tile
          :class="{ 'right-to-left': $vuetify.rtl }"
          :to="{ path: localePath('/help') }"
        >
          <v-list-tile-action>
            <v-icon>contact_support</v-icon>
          </v-list-tile-action>
          <v-list-tile-title>
            {{ $t("Help/Support") }}
          </v-list-tile-title>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <!-- <v-content>
      <router-view></router-view>
    </v-content> -->
    <v-footer app></v-footer>
  </div>
</template>

<script>
// import { isSupported } from "firebase/messaging";
import Vue from "vue";
// import { mapActions, mapGetters } from "vuex";

// import RequestNotificationBadge from "../components/RequestNotificationBadge";
import { changeFavicon } from "../lib/dom_direct";
// import fcmInit from "../lib/fcm/fcmInit";
import LanguageSwitchMixin from "../mixins/LanguageSwitchMixin";
import {
  getUserDetails,
  applyCommonActionWithUri,
  getMerchantConfig
} from "../services/common";
// eslint-disable-next-line import/order
import { getInstalledPlugins, getSectionsConfig } from "../services/plugins";
// import PermissionsRequestTicketService from "../services/tickets/permissionsRequest";
// import RefundVoidRequestTicketService from "../services/tickets/refundVoidRequest";

//import {mapState, mapMutations, mapGetters} from 'vuex';

// import NotificationBadge from "./NotificationBadge.vue";
//import router from '../router/index.js'

export default {
  name: "Dashboard",
  // components: { NotificationBadge, RequestNotificationBadge },
  mixins: [LanguageSwitchMixin /*,fcmInit*/],
  data: () => ({
    drawer: true,
    showNavbar: true,
    installed_plugins: {
      payment_request: false,
      bulk: false
    },
    sections: {},
    headerOptions: {},
    merchant_details: {},
    user_details: {},
    //keys
    permissionRequestGetters: [
      { namespace: "ticket", map: "getUnprocessedCount" }
    ],
    //keys
    operationRequestGetters: [{ namespace: "ticket", map: "getNewOperation" }]
  }),
  computed: {
    // isFCMConfigSet() {
    //   return (
    //     Vue.prototype.$firebaseAPIKey &&
    //     Vue.prototype.$firebaseProjectId &&
    //     Vue.prototype.$firebaseSenderID &&
    //     Vue.prototype.$firebaseAppId &&
    //     Vue.prototype.$firebasePublicKey
    //   );
    // },
    // ...mapGetters("fcm", ["getpayload"])
  },
  watch: {
    // async sections() {
    //   try {
    //     if (this.isSectionAvailable("ticket")) {
    //       /// if ticket and permission changes is enabled => get all ticket then count the new and show it
    //       if (this.sections.ticket.user_permission_changes) {
    //         PermissionsRequestTicketService.getList({
    //           status: "NEW",
    //           limit: 100
    //         }).then(res => {
    //           const count = res.data.results.length;
    //           this.setUnprocessed(count);
    //         });
    //       }
    //       if (this.sections.ticket.operations_approval) {
    //         RefundVoidRequestTicketService.getCount().then(res => {
    //           const count = res.data.count;
    //           this.setNewOperation(count);
    //         });
    //       }
    //     }
    //   } catch (error) {
    //     console.log("Error: ", error);
    //   }
    // },
    // getpayload() {
    //   ///all fcm notification data come here
    //   if ("notification" in this.getpayload) {
    //     /// means order or permission, else if data in payload means payment.
    //     if (this.getpayload.notification.title == "New Permission Request") {
    //       //means permission
    //       this.incrementUnprocessedCount(); /// incress unprocessed count in case of new permisssion request
    //     }
    //     if (this.getpayload.notification.title == "Order created") {
    //       //means order
    //       this.incrementUnreadNotification();
    //       let audio = new Audio();
    //       audio.src = "/new_order_notification.mp3";
    //       audio.play();
    //       this.setUpdateOrderState(true);
    //     }
    //   }
    //   // it could be 'data' in payload this means payment
    // }
  },
  async created() {
    if (Vue.prototype.$token()) {
      try {
        const res = await getUserDetails();
        this.user_details = res.data;
        //vm.setPermissions({is_superuser: data.is_superuser, perms_array: data.user_permissions});
        //vm.setUsername(data.username);
        //vm.setEmail(data.email);
        //vm.setId(data.id);
        //vm.setSentOrderNotification(data.receive_order_notification);
      } catch (err) {
        if (err.response.status === 401) {
          Vue.prototype.$logout();
        }

        this.$processReqError(err);
      }
    }

    if (this.$route.meta.hide_navbar) {
      this.showNavbar = false;
      this.drawer = false;
    }
    // if (this.isFCMConfigSet) {
    //   try {
    //     if (await isSupported()) {
    //       this.initFCMMessaging();
    //       this.fcmInit();
    //     } else {
    //       console.log("Not supported engine");
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // }
    try {
      const res = await this.$processReq(getInstalledPlugins(), false);
      res.data.forEach(plugin => {
        this.installed_plugins[plugin] = true;
      });
    } catch (error) {
      console.log("Error: ", error);
    }
    try {
      const res = await this.$processReq(getSectionsConfig(), false);
      this.sections = res.data;
      this.getHeaders();
      // Object.assign(this.$data, {});
    } catch (error) {
      console.log("Error: ", error);
    }
    try {
      const res = await getMerchantConfig();
      this.setMerchantConfigs(res.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  },
  methods: {
    clickDrawer() {
      this.drawer = !this.drawer;
      const element = document.getElementsByClassName("application")[0];
      if (!this.drawer) {
        element.classList.remove("navbarShow");
      } else {
        element.classList.add("navbarShow");
      }
    },
    hasHeadersPerm(name) {
      if (
        this.user_details.is_superuser ||
        (Object.keys(this.user_details.user_permissions).length > 0 &&
          this.user_details.user_permissions[
            `change_${name}transactionproxyfield`
          ] !== undefined)
      ) {
        return true;
      } else return false;
      // console.log(this.user_details,'name perm--->',name)
      // return true
    },
    setMerchantConfigs(data) {
      const {
        admin_url,
        logo_url,
        favicon_url,
        title,
        title_en,
        title_ar,
        subheader
      } = data;
      this.merchant_details = {
        admin_url,
        logo_url,
        favicon_url,
        title,
        title_en,
        title_ar,
        subheader
      };

      if (favicon_url) {
        changeFavicon(favicon_url);
      }
      if (title) {
        document.title = title;
      }
    },
    logout() {
      Vue.prototype.$logout();
    },
    getHeaders() {
      Object.keys(this.sections).map(async key => {
        if (key === "express_search") {
          return;
        }

        try {
          const res = await applyCommonActionWithUri(
            "plugins/transaction-fields/check/" + key + "/"
          );
          this.$set(this.headerOptions, key, res.data.enabled);
        } catch (error) {
          console.log("Error: ", error);
        }
      });
    },
    isSectionAvailable(section_name) {
      const section_config = this.sections[section_name];
      return (
        section_config &&
        Object.keys(section_config).some(k => section_config[k] === true)
      );
    },
    // enabledNotification() {
    //   return [
    //     ...this.permissionRequestGetters,
    //     ...this.operationRequestGetters
    //   ];
    // },
    // ...mapActions("ticket", [
    //   "setUnprocessed",
    //   "incrementUnprocessedCount",
    //   "setNewOperation"
    //   // 'decreaseNewOperation'
    // ])
    // ...mapMutations('dashboard', [
    //   'setPermissions',
    //   'setUsername',
    //   'setEmail',
    //   'setSentOrderNotification',
    //   'setId'
    // ])
  }
};
</script>

<style>
.application--wrap {
  min-height: auto !important;
}
.ottu-logo {
  margin-left: 10px;
  margin-right: 10px;
  height: 40px;
}
.rounded-card {
  border-radius: 0.625rem !important;
}
.v-navigation-drawer > .v-list .v-list__tile--active .v-list__tile__title {
  color: #f77c1a !important;
}
.v-list__tile--active {
  background: transparent !important;
  font-weight: 700 !important;
  transition: 0.3s;
}
.theme--light.v-list .v-list__tile--link:hover,
.theme--light.v-list .v-list__tile--highlighted,
.theme--light.v-list .v-list__group__header:hover:hover {
  background: transparent !important;
}
.v-list__tile__title {
  transition: 0.3s;
  text-overflow: initial;
}
.v-list__tile__title:hover {
  /* color: #f77c1a;  */
  color: var(--primary);
}
.v-list__group__items {
  padding-left: 4rem !important;
}
.submenu {
  margin-left: -2rem;
}
.application--is-rtl .v-list__group__items {
  padding-right: 4rem !important;
  padding-left: unset !important;
}
.application--is-rtl .v-list__tile__title.submenu {
  margin-right: -2rem !important;
}
.right-to-left > .v-list__group__items {
  padding-left: 0 !important;
}
.right-to-left > .v-list__group__items .v-list__tile__title {
  padding-right: 6rem;
}
.right-to-left .v-list__group__header .v-list__tile {
  padding: 0 !important;
}

.v-list__tile_real .v-list__tile {
  padding: 0px !important;
}
.right-to-left .v-list__tile__title {
  text-align: right;
  padding-right: 0rem;
}
.right-to-left .v-list__group > .v-list__group__header .v-list__tile__title {
  text-align: right;
  padding-right: 4rem;
}
.right-to-left .v-list__group > .v-list__group__items .v-list__tile__title {
  text-align: right;
  padding-right: 6rem;
}
.right-to-left .v-list__group > .v-list__group__items {
  padding-left: 0rem !important;
}
</style>

<style scoped>
.subheading {
  text-align: center;
}
</style>
